import React, { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { useParams } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import { useGetUser } from "../hooks/useGetUser";
import _ from "lodash";
import { AudioPlayer } from "../component/audio-player";

export function Mistakes() {
  const params = useParams();
  const { id } = params;
  const { user, loading, error } = useGetUser(id);
  const [view, setView] = useState<"major" | "minor" | "tajweed">("major");

  const majorAudio = [
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/002.mp3",
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/003.mp3",
  ];

  const minorAudio = [
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/004.mp3",
  ];

  const tajweedAudio = [
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/005.mp3",
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/006.mp3",
    "https://download.quranicaudio.com/quran/abdullaah_3awwaad_al-juhaynee/007.mp3",
  ];

  const getCurrentAudio = () => {
    switch (view) {
      case "major":
        return majorAudio;
      case "minor":
        return minorAudio;
      case "tajweed":
        return tajweedAudio;
      default:
        return [];
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  return (
    <>
      <HeaderNav title="New Lesson - Mistakes" />
      {error != null || _.isEmpty(user) ? (
        <div className="alert alert-danger m-3">We encountered a problem</div>
      ) : null}
      <div className="flex items-center justify-center">
        <Tabs defaultValue="major" className="bg-white mt-5 mistake-nav-tabs">
          <TabsList className="bg-white">
            <TabsTrigger
              value="major"
              onClick={() => setView("major")}
              className={
                view === "major"
                  ? "data-[state=active]:bg-[#DAF4F4] data-[state=active]:text-primary"
                  : ""
              }
            >
              Major ({majorAudio.length})
            </TabsTrigger>
            <TabsTrigger
              value="minor"
              onClick={() => setView("minor")}
              className={
                view === "minor"
                  ? "data-[state=active]:bg-[#DAF4F4] data-[state=active]:text-primary"
                  : ""
              }
            >
              Minor ({minorAudio.length})
            </TabsTrigger>
            <TabsTrigger
              value="tajweed"
              onClick={() => setView("tajweed")}
              className={
                view === "tajweed"
                  ? "data-[state=active]:bg-[#DAF4F4] data-[state=active]:text-primary"
                  : ""
              }
            >
              Tajweed ({tajweedAudio.length})
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <div className="ms-auto text-center mt-7 w-full px-4">
        {getCurrentAudio().map((audioSrc, index) => (
          <div key={index} className="mb-4">
            <AudioPlayer audioSrc={audioSrc} />
          </div>
        ))}
      </div>
    </>
  );
}
