import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { FooterNav } from "./components/FooterNav";
import { Student } from "./components/Student";
import { Students } from "./components/Students";
import { CreateAssignment } from "./components/CreateAssignment";
import { Mistakes } from "./components/Mistakes";
import { Login } from "./components/Login";
import AttendanceChart from "./components/Attendance";
import { EditAssignment } from "./components/EditAssignment";
import AudioRecorderComponent from "./components/AudioRecorderComponent";
import { Toaster } from "./components/ui/toaster";
import { AuthProvider } from './contexts/AuthContext';
import { ReviewAssignment } from './components/ReviewAssignment';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Toaster />
        <Routes >
          <Route path="/" element={<Login />} />
          <Route path="dashboard" element={<Student />} />
          <Route path="students" element={<Students />} />
          <Route path="student/:id" element={<Student />} />
          <Route path="student/:id/create-assignment" element={<CreateAssignment />} />
          <Route path="student/:assignmentId/edit-assignment" element={<EditAssignment />} />
          <Route path="student/:id/review-assignment" element={<ReviewAssignment />} />
          <Route path="student/:id/attendance" element={<AttendanceChart />} />
          <Route path="mistakes" element={<Mistakes />} />
          <Route path="mic" element={<AudioRecorderComponent />} />
          <Route path="*" element={<Login />} />
        </Routes>
        <FooterNav />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
