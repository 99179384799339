import { useState, useEffect } from 'react'
import api from '../services/axios'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { BackendError } from '../types/error'
import { User } from '../types/common'

interface AttendanceList {
    forDate: Date,
    users: User[]
}

export const useGetUsers = () => {
    const [users, setUsers] = useState<User[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        api.get<AttendanceList>('/api/attendance')
            .then((response) => {
                setUsers(response.data?.users)
            })
            .catch((err: AxiosError<BackendError>) => {
                const statusCode = err.response?.status
                if (statusCode === 403) {
                    navigate(`/login`);
                }
                setError(err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [navigate])

    return { users, loading, error }
}