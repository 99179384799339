import React from 'react'
import api from '../services/axios'
import { toast } from './useToast'
import { CircleAlert } from 'lucide-react'
import { Assignment } from '../types/common'

export const useGetAssignment = (assignmentId: number) => {
    const [assignment, setAssignment] = React.useState<Assignment>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<any>(null)

    const fetchAssignment = React.useCallback(async () => {
        setLoading(true)
        try {
            const response = await api.get(`/api/assignment/${assignmentId}`)
            setAssignment(response.data)
            setError(null)
        } catch (error: any) {
            console.error(error)
            const title = error?.response?.data?.title ?? 'An Error has occurred'
            const description = error?.response?.data?.message ?? 'Lesson could not be created'
            toast({
                title,
                description,
                icon: (
                    <CircleAlert
                        className="text-white dark:text-slate-900"
                        fill="red"
                    />
                ),
            })
            setError(error)
        } finally {
            setLoading(false)
        }
    }, [assignmentId])

    React.useEffect(() => {
        fetchAssignment()
    }, [fetchAssignment])

    return {
        assignment,
        loading,
        error,
        refetch: fetchAssignment
    }
}