export interface User {
  id: string;
  username: string;
  name: string;
  role: string;
  attendanceStatus?: 'present' | 'absent' | 'late';
}

export enum AssignmentStatus {
  pass = 'pass',
  fail = 'fail',
  partial_pass = 'partial_pass',
  pause = 'pause',
  draft = 'draft',
}

export enum AssignmentType {
  new = 'new',
  past = 'past',
  seven_pages = 'seven_pages',
  revision = 'revision',
}

export interface ReviewCounts {
    minor: number;
    major: number;
    tajweed: number;
    total: number;
  }

export interface Assignment {
  id: number;
  Student: User;
  studentId: number;
  Sections: Section[];
  type: AssignmentType;
    status: AssignmentStatus;
    reviewCounts: ReviewCounts;
}

export interface Section {
  surahNumber: number;
  startVerse: number;
  endVerse: number;
}

export enum AttendanceStatus {
  present = 'present',
  late = 'late',
  absent = 'absent',
}

export interface AttendanceAggregate {
  present: number;
  late: number;
  absent: number;
}

export enum AssignmentMutationMode {
  update = 'update',
  create = 'create',
}
