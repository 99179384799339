import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderNav from './HeaderNav';
import { useGetUser } from '../hooks/useGetUser';
import { Card, CardHeader, CardContent } from './ui/card';
import { Button } from './ui/button';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from './ui/select';
import {
  CircleAlert,
  CircleCheck,
  CircleEllipsis,
  CirclePause,
  CircleX,
  Plus,
} from 'lucide-react';
import { useGetAssignments } from '../hooks/useGetAssignments';
import _ from 'lodash';
import { surahNameMap } from '../utils/surahUtils';
import { Assignment, AssignmentType, ReviewCounts } from '../types/common';
import api from '../services/axios';
import { AxiosError } from 'axios';
import { BackendError } from '../types/error';
import { toast } from '../hooks/useToast';
import { useAuth } from '../contexts/AuthContext';
import { Skeleton } from './ui/skeleton';

interface AssignmentProps {
  assignment: Assignment;
}
interface ReviewCountsDisplayProps {
  reviewCounts?: ReviewCounts;
  showTajweed?: boolean;
}

export function Student() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { isAtLeastTeacher } = useAuth();
  const { user, loading: userLoading } = useGetUser(id);
  const {
    assignments,
    loading: assignmentsLoading,
    refetch,
  } = useGetAssignments(id);
  const [assignmentResult, setAssignmentResult] = React.useState<{
    [key: string]: string;
  }>({});

  // Define the fixed order of assignment types
  const assignmentTypeOrder = [
    AssignmentType.new,
    AssignmentType.past,
    AssignmentType.seven_pages,
    AssignmentType.revision,
  ];

  function handleStatusChange(assignmentId: number, value: string) {
    // toast({
    //   title: 'Cannot perform this action.',
    //   description: 'Please be sure to update Past Assignment status before proceeding.',
    //   icon: (
    //     <CircleAlert
    //       className="text-white dark:text-slate-900"
    //       fill="red"
    //     />
    //   ),
    // })
    setAssignmentResult((prev) => ({
      ...prev,
      [assignmentId]: value,
    }));
    api
      .put(`/api/assignment/${assignmentId}/status`, {
        status: value,
      })
      .then(() => {
        refetch();
      })
      .catch((error: AxiosError<BackendError>) => {
        const title = error?.response?.data?.title ?? 'An Error has occurred';
        const description =
          error?.response?.data?.message ?? 'Lesson could not be created';
        toast({
          title,
          description,
          icon: (
            <CircleAlert
              className='text-white dark:text-slate-900'
              fill='red'
            />
          ),
        });
      });
  }

  const getSelectTriggerStyle = (assignment: Assignment) => {
    switch (assignmentResult[assignment.id] ?? assignment.status) {
      case 'partial_pass':
        return 'bg-[#FBF2D9] text-[#B18708]';
      case 'pass':
        return 'bg-[#E4F3E5] text-[#309C34]';
      case 'fail':
        return 'bg-[#F3E4E4] text-[#9C3030]';
      case 'in_progress':
        return 'bg-[#E4EFF3] text-[#0067B3]';
      case 'pause':
        return 'bg-[#EEEEEE] text-[#444444]';
      default:
        return '';
    }
  };

  const DropDown = ({
    assignment,
    value,
  }: {
    assignment: Assignment;
    value: string;
  }) => {
    return (
      <Select
        value={assignmentResult[assignment.id] ?? value ?? undefined}
        onValueChange={(newValue) =>
          handleStatusChange(assignment.id, newValue)
        }
      >
        <SelectTrigger
          className={`p-3 w-full max-w-[150px] border-0 font-medium bg-[#EEEEEE] text-[#444444] ${getSelectTriggerStyle(
            assignment
          )}`}
        >
          <SelectValue placeholder='Not Started' />
        </SelectTrigger>
        <SelectContent className='p-2 min-w-[180px]'>
          <SelectGroup>
            <SelectItem
              value='draft'
              className=' font-medium mb-2 py-2 border-0'
            >
              <div className='flex items-center'>
                <CircleEllipsis className='h-4 w-4 mr-2' />
                <span>Not Started</span>
              </div>
            </SelectItem>
            <SelectItem
              value='pass'
              className='bg-[#E4F3E5] text-[#309C34] font-medium mb-2 py-2 border-0'
            >
              <div className='flex items-center'>
                <CircleCheck className='h-4 w-4 mr-2' />
                <span>Pass</span>
              </div>
            </SelectItem>
            <SelectItem
              value='partial_pass'
              className='bg-[#FBF2D9] text-[#B18708] font-medium mb-2 py-2 border-0'
            >
              <div className='flex items-center'>
                <CircleAlert className='h-4 w-4 mr-2' />
                <span>Partial Pass</span>
              </div>
            </SelectItem>
            <SelectItem
              value='fail'
              className='bg-[#F3E4E4] text-[#9C3030] font-medium mb-2 py-2 border-0'
            >
              <div className='flex items-center'>
                <CircleX className='h-4 w-4 mr-2' />
                <span>Fail</span>
              </div>
            </SelectItem>
            <SelectItem
              value='in_progress'
              className='bg-[#E4EFF3] text-[#0067B3] font-medium mb-2 py-2 border-0'
            >
              <div className='flex items-center'>
                <CircleEllipsis className='h-4 w-4 mr-2' />
                <span>In Progress</span>
              </div>
            </SelectItem>
            <SelectItem
              value='pause'
              className='bg-[#EEEEEE] text-[#444444] font-medium py-2 border-0'
            >
              <div className='flex items-center'>
                <CirclePause className='h-4 w-4 mr-2' />
                <span>Pause</span>
              </div>
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    );
  };

  const AssignmentCard: React.FC<AssignmentProps> = (
    props: AssignmentProps
  ) => {
    const { assignment } = props;
    return (
      <Card className='shadow-centered mt-3'>
        <CardHeader className='border-b py-2 pl-4 pr-3'>
          <div className='flex justify-between items-center'>
            <span className='w-full font-bold title-dark-green'>
              {_.startCase(_.toLower(assignment.type))} Lesson
            </span>
            {isAtLeastTeacher && (
              <DropDown assignment={assignment} value={assignment.status} />
            )}
          </div>
        </CardHeader>
        <CardContent className='px-4'>
          <div>
            {assignment.Sections &&
              assignment.Sections.map((s: any, i: number) => {
                return (
                  <div key={i}>
                    <div className='flex justify-between items-center mt-4 mb-2'>
                      <span>
                        {surahNameMap[s.surahNumber]} ({s.surahNumber})
                      </span>
                    </div>
                    <div className='flex justify-between items-center'>
                      <span className='text-sm text-foreground font-bold'>
                        Verses {s.startVerse}-{s.endVerse}
                      </span>
                    </div>
                  </div>
                );
              })}
            <div className='flex justify-between items-center mt-5'>
              <span className=' text-gray-600'>Mistakes</span>
            </div>
            <div className='flex justify-between items-center'>
              <span className='text-sm font-bold text-gray-600'>
                <ReviewCountsDisplay
                  reviewCounts={assignment.reviewCounts}
                  showTajweed={true}
                />
              </span>
              {/* {isAtLeastTeacher && ['draft', 'fail', 'partial_pass'].includes(assignment.status) && */}
              <Button
                className='font-bold p-0 text-primary underline'
                variant='link'
                onClick={() => {
                  if (user) {
                    navigate(`/student/${assignment.id}/edit-assignment`, {
                      state: { user },
                    });
                  }
                }}
              >
                Details
              </Button>
              {/* } */}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  const ReviewCountsDisplay = ({
    reviewCounts,
    showTajweed = false,
  }: ReviewCountsDisplayProps) => {
    const counts = reviewCounts || { minor: 0, major: 0, tajweed: 0, total: 0 };

    return (
      <span>
        {counts.major} Major <span className='font-normal'>|</span>{' '}
        {counts.minor} Minor
        {showTajweed && (
          <>
            <span className='font-normal'> | </span>
            {counts.tajweed} Tajweed
          </>
        )}
      </span>
    );
  };

  // Function to render cards for a specific type (either populated or empty)
  const renderAssignmentSection = (type: AssignmentType) => {
    const assignmentsOfType = assignments.filter(
      (a: Assignment) => a.type === type
    );

    if (isAtLeastTeacher && assignmentsOfType.length === 0) {
      return (
        <Card className='border-dashed' key={`empty-${type}`}>
          <CardHeader className='border-b py-3 px-4  title-dark-green'>
            <div className='flex justify-between items-center'>
              <span className='font-bold'>{_.startCase(_.toLower(type))}</span>
            </div>
          </CardHeader>
          <CardContent>
            <div className='text-center'>
              <Button
                className='d-flex items-center justify-center w-full mt-6 h-20 text-primary font-bold text-base'
                variant='ghost'
                onClick={() =>
                  navigate(`/student/${user?.id}/create-assignment`, {
                    state: { user, type },
                  })
                }
              >
                <Plus className='h-6 w-6 mr-2' strokeWidth={2.6} /> Add{' '}
                {_.startCase(_.toLower(type))} Lesson
              </Button>
            </div>
          </CardContent>
        </Card>
      );
    } else if (!isAtLeastTeacher && assignmentsOfType.length === 0) {
      return <Skeleton className='h-6 w-full max-w-100' />;
    }

    // Render populated cards for this type
    return assignmentsOfType.map((assignment: Assignment, index: number) => (
      <AssignmentCard
        key={`${type}-${assignment.id}`}
        assignment={assignment}
      />
    ));
  };

  if (userLoading) {
    return <div className='flex items-center justify-center h-screen'></div>;
  }

  return (
    <div className="assignments-bg-green min-h-screen">
      <HeaderNav title={<>{user?.name}</>} />
      <div className='p-3 pt-5 pb-24 assignments-bg-green'>
        <div className='space-y-3 w-full mx-auto md:w-4/5 xl:w-3/5'>
          {!assignmentsLoading &&
            assignmentTypeOrder.map((type: AssignmentType) => (
              <React.Fragment key={type}>
                {renderAssignmentSection(type)}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
}
