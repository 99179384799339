import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Skeleton } from "./ui/skeleton";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { PieChart, Pie, Cell, Label } from "recharts";
import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "./ui/chart";
import { format, startOfMonth, startOfYear } from "date-fns";
import HeaderNav from "./HeaderNav";
import { useGetUser } from "../hooks/useGetUser";
import { useGetAggregateAttendance } from '../hooks/useGetAggregateAttendance';
import { AttendanceAggregate } from '../types/common';

interface ChartConfig {
  [key: string]: {
    label: string;
    color: string;
  };
}

const StudentAttendance: React.FC = () => {
  const params = useParams();
  const { id } = params;
  const { user } = useGetUser(id);

  const initObj = { present: 0, absent: 0, late: 0 };
  const [currentTab, setCurrentTab] = useState("monthly");
  const [attendanceSummary, setAttendanceSummary] = useState<AttendanceAggregate>(initObj);

  const { monthlyAttendanceSummary, yearlyAttendanceSummary, loading } = useGetAggregateAttendance(id);

  // Update attendance summary when data loads or tab changes
  useEffect(() => {
    const summary = currentTab === "monthly" ? monthlyAttendanceSummary : yearlyAttendanceSummary;
    if (summary) {
      setAttendanceSummary({
        present: summary.present || 0,
        absent: summary.absent || 0,
        late: summary.late || 0
      });
    }
  }, [currentTab, monthlyAttendanceSummary, yearlyAttendanceSummary]);

  const totalAttendance = Math.max(1,
    (attendanceSummary.present || 0) +
    (attendanceSummary.late || 0) +
    (attendanceSummary.absent || 0)
  );

  const presentPercentage = Math.round((attendanceSummary.present || 0) / totalAttendance * 100);

  const chartData = [
    {
      name: "Present",
      value: ((attendanceSummary.present || 0) / totalAttendance) * 100,
    },
    {
      name: "Late",
      value: ((attendanceSummary.late || 0) / totalAttendance) * 100
    },
    {
      name: "Absent",
      value: ((attendanceSummary.absent || 0) / totalAttendance) * 100,
    },
  ].filter(item => item.value > 0); // Only show non-zero values

  const barChartData = [
    { status: "Present", count: attendanceSummary.present || 0 },
    { status: "Late", count: attendanceSummary.late || 0 },
    { status: "Absent", count: attendanceSummary.absent || 0 },
  ].filter(item => item.count > 0); // Only show non-zero values

  const chartConfig: ChartConfig = {
    present: {
      label: "Present",
      color: "#3ed675",
    },
    late: {
      label: "Late",
      color: "#f5e46c",
    },
    absent: {
      label: "Absent",
      color: "#ed5858",
    },
  };

  const handleTabChange = (value: string) => {
    setCurrentTab(value);
  };

  const currentMonthFirstDate = format(startOfMonth(new Date()), "MM/dd/yy");
  const today = format(new Date(), "MM/dd/yy");
  const currentYearFirstDate = format(startOfYear(new Date()), "MM/dd/yy");

  return (
    <>
      <HeaderNav title={<>{user?.name}</>} />
      <div className="p-4 space-y-8 max-w-7xl mx-auto mb-44">
        <Card className="w-full p-0">
          <CardHeader className="border-b px-5 py-4 flex justify-between flex-row items-center w-full">
            <CardTitle className="font-bold text-xl w-full">Attendance</CardTitle>
            <div className="flex flex-col items-end w-full">
              <CardTitle className="font-normal text-base">
                {currentTab === "monthly"
                  ? `${currentMonthFirstDate} - ${today}`
                  : `${currentYearFirstDate} - ${today}`}
              </CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            {loading ? (
              <>
                <Skeleton className="h-64 w-64 mx-auto rounded-full mt-10" />
                <Skeleton className="h-96 w-full mt-20" />
              </>
            ) : chartData.length > 0 ? (
              <>
                <ChartContainer config={chartConfig} className="mx-auto w-64 h-64 sm:scale-125 sm:my-10">
                  <PieChart>
                    <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                    <Pie
                      data={chartData}
                      dataKey="value"
                      nameKey="name"
                      innerRadius={90}
                      strokeWidth={5}
                      animationDuration={1000}
                      animationBegin={0}
                    >
                      <Label
                        content={({ viewBox }) => {
                          if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                            return (
                              <text
                                x={viewBox.cx}
                                y={viewBox.cy}
                                textAnchor="middle"
                                dominantBaseline="middle"
                              >
                                <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-2xl font-bold">
                                  {`${presentPercentage}%`}
                                </tspan>
                                <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground font-medium text-base">
                                  Present
                                </tspan>
                              </text>
                            );
                          }
                          return null;
                        }}
                      />
                      {chartData.map((entry: { name: string }, index) => (
                        <Cell
                          key={index}
                          fill={chartConfig[entry.name.toLowerCase()].color}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ChartContainer>

                <Tabs defaultValue="monthly" onValueChange={handleTabChange} className="mt-8">
                  <TabsList className="w-full space-x-7 bg-transparent">
                    <TabsTrigger value="monthly" className="text-blue-600 font-bold data-[state=active]:bg-blue-50 data-[state=active]:text-blue-600 data-[state=active]:shadow-none">
                      Monthly
                    </TabsTrigger>
                    <TabsTrigger value="yearly" className="text-blue-600 font-bold data-[state=active]:bg-blue-50 data-[state=active]:text-blue-600 data-[state=active]:shadow-none">
                      Yearly
                    </TabsTrigger>
                  </TabsList>
                </Tabs>

                {barChartData.length > 0 && (
                  <Card className="border-0 shadow-none p-0 m-0 mt-10">
                    <CardContent>
                      <ChartContainer config={chartConfig} className="min-w-[300px] max-h-[300px] min-h-[200px] w-full mx-auto -ml-8 sm:ml-0">
                        <BarChart data={barChartData} layout="vertical" margin={{ right: 30 }} barGap={5}>
                          <YAxis dataKey="status" type="category" tickLine={false} axisLine={false} className="font-medium" />
                          <XAxis dataKey="count" type="number" hide />
                          <Bar dataKey="count" radius={5} barSize={20}>
                            {barChartData.map((entry, index) => (
                              <Cell key={index} fill={chartConfig[entry.status.toLowerCase()].color} />
                            ))}
                            <LabelList dataKey="count" position="right" offset={15} className="font-medium fill-foreground" />
                          </Bar>
                        </BarChart>
                      </ChartContainer>
                    </CardContent>
                  </Card>
                )}
              </>
            ) : (
              <div className="text-center py-10 text-gray-500">
                No attendance data available for this period
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default StudentAttendance;