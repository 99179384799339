import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';
import { Card, CardContent } from './ui/card';
import HeaderNav from './HeaderNav';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircleCheck, Pause, Play } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
} from './ui/drawer';
import { Textarea } from './ui/textarea';
import { Surah, surahs } from '../utils/surahUtils';
import { useGetAssignment } from '../hooks/useGetAssignment';
import { AssignmentMutationMode } from '../types/common';

type MistakeType = 'Major' | 'Minor' | 'Tajweed';

interface MistakeFormData {
  type: MistakeType | null;
  startVerse: number | null;
  endVerse: number | null;
  recordingTime: number;
}

export function EditAssignment() {
  const [filteredSurahs, setFilteredSurahs] = useState<Surah[]>([]);
  const { state } = useLocation();
  const params = useParams();
  const { assignmentId } = params;
  const { assignment, loading: assignmentLoading } = useGetAssignment(
    +assignmentId!
  );
  const [expandedAccordionItems, setExpandedAccordionItems] = useState<
    string[]
  >([]);
  const [verses, setVerses] = useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null };
  }>({});
  const [initialVerses, setInitialVerses] = useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null };
  }>({});
  const [entireVerse, setEntireVerse] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [initialEntireVerse, setInitialEntireVerse] = useState<{
    [key: number]: boolean;
  }>({});
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [isMistakeDrawerOpen, setMistakeDrawerOpen] = useState(false);
  const [mistakeFormData, setMistakeFormData] = useState<MistakeFormData>({
    type: null,
    startVerse: null,
    endVerse: null,
    recordingTime: 0,
  });
  const [isRecording, setIsRecording] = useState(false);
  // const [recordingTime, setRecordingTime] = useState(0);

  React.useEffect(() => {
    if (Object.keys(surahs).length > 0) {
      setFilteredSurahs(surahs);
    }
  }, []);

  React.useEffect(() => {
    if (assignment?.Sections && !assignmentLoading) {
      const newVerses: {
        [key: number]: { startVerse: number | null; endVerse: number | null };
      } = {};
      const newEntireVerse: { [key: number]: boolean } = {};
      const expandedItems: string[] = [];

      // Process each section from the assignment
      assignment.Sections.forEach((section) => {
        // Add verse data
        newVerses[section.surahNumber] = {
          startVerse: section.startVerse,
          endVerse: section.endVerse,
        };

        // Set entireVerse to false initially for this surah
        newEntireVerse[section.surahNumber] = false;

        // Add to expanded items
        expandedItems.push(section.surahNumber.toString());
      });

      // Set all state at once
      setVerses(newVerses);
      setInitialVerses({ ...newVerses });
      setEntireVerse(newEntireVerse);
      setInitialEntireVerse({ ...newEntireVerse });
      setExpandedAccordionItems(expandedItems);
    }
  }, [assignment, assignmentLoading]);

  const handleClearVerse = (surahNumber: number) => {
    setVerses((prev) => {
      const newVerses = { ...prev };
      delete newVerses[surahNumber];
      return newVerses;
    });

    setEntireVerse((prev) => {
      const newEntireVerse = { ...prev };
      delete newEntireVerse[surahNumber];
      return newEntireVerse;
    });

    setExpandedAccordionItems((prev) =>
      prev.filter((item) => item !== surahNumber.toString())
    );
  };

  const handleConfirmLesson = () => {
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.update,
      assignmentId,
    };
    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: { id: assignmentId, Sections: { ...selectedData } },
        user: state.user,
        ...selectedData,
        mode: AssignmentMutationMode.update,
      },
      replace: true,
    });
  };

  const handleAddNote = () => {
    setDrawerOpen(true);
  };

  const handleSaveNote = () => {
    setDrawerOpen(false);
  };

  const handleVerseChange = (surahNumber: number) => {
    const verseData = verses[surahNumber];
    const hasVerses =
      verseData &&
      (verseData.startVerse !== null || verseData.endVerse !== null);
    return hasVerses || entireVerse[surahNumber];
  };

  const hasChanges = (): boolean => {
    // Create an array of all surah numbers from both current and initial states
    const surahNumbers = Array.from(
      new Set([
        ...Object.keys(verses).map(Number),
        ...Object.keys(initialVerses).map(Number),
      ])
    );

    for (const surahNumber of surahNumbers) {
      const current = verses[surahNumber] || {
        startVerse: null,
        endVerse: null,
      };
      const initial = initialVerses[surahNumber] || {
        startVerse: null,
        endVerse: null,
      };
      const entireVerseChanged =
        entireVerse[surahNumber] !== initialEntireVerse[surahNumber];

      if (
        entireVerseChanged ||
        current.startVerse !== initial.startVerse ||
        current.endVerse !== initial.endVerse
      ) {
        return true;
      }
    }

    return false;
  };

  const handleAddMistake = () => {
    setMistakeDrawerOpen(true);
    setMistakeFormData({
      type: null,
      startVerse: null,
      endVerse: null,
      recordingTime: 0,
    });
  };

  const handleMistakeTypeSelect = (type: MistakeType) => {
    setMistakeFormData((prev) => ({
      ...prev,
      type,
    }));
  };

  const handleRecordingToggle = () => {
    setIsRecording(!isRecording);
  };

  const handleMistakeSubmit = () => {
    // Handle the mistake submission logic here
    setMistakeDrawerOpen(false);
    // Reset form data
    setMistakeFormData({
      type: null,
      startVerse: null,
      endVerse: null,
      recordingTime: 0,
    });
  };

  return (
    <>
      <HeaderNav title='Edit Lesson' />
      <Card className='w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5'>
        <CardContent className='p-0'>
          <Tabs defaultValue='lesson-details' className='w-full mt-4'>
            <TabsList className='px-0 w-full bg-transparent relative'>
              <TabsTrigger
                value='lesson-details'
                className='text-base relative w-full py-2 text-foreground font-bold border-b-2 border-transparent transition-all duration-300 ease-in-out data-[state=active]:bg-transparent data-[state=active]:text-primary data-[state=active]:shadow-none data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:rounded-none'
              >
                Lesson Details
              </TabsTrigger>
              <TabsTrigger
                disabled={hasChanges()}
                value='mistakes'
                className='text-base relative w-full py-2 text-foreground font-bold border-b-2 border-transparent transition-all duration-300 ease-in-out data-[state=active]:bg-transparent data-[state=active]:text-primary data-[state=active]:shadow-none data-[state=active]:border-b-2 data-[state=active]:border-primary data-[state=active]:rounded-none'
              >
                Mistakes
              </TabsTrigger>
            </TabsList>

            <TabsContent value='lesson-details'>
              <Accordion
                value={expandedAccordionItems}
                onValueChange={setExpandedAccordionItems}
                type='multiple'
                className='mb-10'
              >
                {filteredSurahs.map((d: Surah) => (
                  <AccordionItem key={d.surahNumber} value={`${d.surahNumber}`}>
                    <AccordionTrigger className='px-5'>
                      <div className='flex justify-between items-center w-full'>
                        <span className='font-bold flex items-center'>
                          {handleVerseChange(d.surahNumber) && (
                            <CircleCheck
                              className='w-5 h-5 text-white'
                              fill='#006766'
                            />
                          )}
                          <span
                            className={
                              handleVerseChange(d.surahNumber)
                                ? 'text-primary'
                                : ''
                            }
                          >
                            {d.name}
                          </span>
                        </span>
                        <span className='text-gray-500 font-normal'>
                          {d.endVerse - d.startVerse + 1} Verses
                        </span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className='px-5'>
                      <div className='flex align-items-center gap-7 mt-4'>
                        <div className='w-full'>
                          <Label>Starting Verse</Label>
                          <Input
                            type='number'
                            placeholder={`${d.startVerse}`}
                            min={d.startVerse}
                            max={d.endVerse}
                            disabled={entireVerse[d.surahNumber]}
                            value={verses[d.surahNumber]?.startVerse || ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              setVerses((prev) => ({
                                ...prev,
                                [d.surahNumber]: {
                                  ...prev[d.surahNumber],
                                  startVerse: isNaN(value) ? null : value,
                                },
                              }));
                            }}
                            className='mt-2'
                          />
                        </div>
                        <div className='w-full'>
                          <Label>Ending Verse</Label>
                          <Input
                            type='number'
                            placeholder={`${d.endVerse}`}
                            min={d.startVerse}
                            max={d.endVerse}
                            disabled={entireVerse[d.surahNumber]}
                            value={verses[d.surahNumber]?.endVerse || ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              setVerses((prev) => ({
                                ...prev,
                                [d.surahNumber]: {
                                  ...prev[d.surahNumber],
                                  endVerse: isNaN(value) ? null : value,
                                },
                              }));
                            }}
                            className='mt-2'
                          />
                        </div>
                      </div>
                      <div className='mt-4 flex justify-between items-center'>
                        <div className='flex items-center'>
                          <Switch
                            id={`entire-verse-${d.surahNumber}`}
                            checked={entireVerse[d.surahNumber] || false}
                            onCheckedChange={(checked) => {
                              // Update entireVerse state
                              setEntireVerse((prev) => ({
                                ...prev,
                                [d.surahNumber]: checked,
                              }));

                              // Update verses state based on the checked state
                              setVerses((prev) => ({
                                ...prev,
                                [d.surahNumber]: {
                                  startVerse: checked ? d.startVerse : null,
                                  endVerse: checked ? d.endVerse : null,
                                },
                              }));
                            }}
                          />
                          <Label
                            htmlFor={`entire-verse-${d.surahNumber}`}
                            className={`ml-2 ${
                              entireVerse[d.surahNumber] ? 'text-primary' : ''
                            }`}
                          >
                            Entire Surah
                          </Label>
                        </div>
                        <div className='space-x-3'>
                          <Button
                            variant='link'
                            className='text-sm p-0 underline font-bold'
                            onClick={() => handleClearVerse(d.surahNumber)}
                          >
                            Clear
                          </Button>
                          <Button
                            variant='link'
                            className='text-sm p-0 underline font-bold'
                            onClick={handleAddNote}
                          >
                            Add Note
                          </Button>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </TabsContent>

            <TabsContent value='mistakes'>
              <div className='p-5'>
                <div className='text-center'>
                  <p className='text-gray-500 mb-4'>
                    Click below to add mistakes
                  </p>
                  <Button
                    onClick={handleAddMistake}
                    className='w-full max-w-md bg-primary text-white py-4'
                  >
                    Record Mistake
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
      <div className='bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end'>
        <Button variant='ghost' onClick={() => navigate(-1)} className='mr-3'>
          Cancel
        </Button>
        <Button onClick={handleConfirmLesson} disabled={!hasChanges()}>
          Confirm
        </Button>
      </div>

      <Drawer open={isDrawerOpen} onOpenChange={setDrawerOpen}>
        <DrawerContent className='fixed inset-x-0 bottom-0 bg-primary border-primary'>
          <DrawerHeader className='p-4 m-0 text-white'>
            <DrawerTitle className='text-left py-1 text-xl'>
              Add Note
            </DrawerTitle>
          </DrawerHeader>
          <div className='p-4 bg-white'>
            <Label htmlFor='note' className='text-base'>
              Enter in your note for lesson Al-Imran, 1-10
            </Label>
            <Textarea
              id='note'
              placeholder='Write your note here...'
              className='mt-2 min-h-[200px] w-full p-4 border rounded-md border-gray-300'
            />
          </div>
          <DrawerFooter className='p-4 bg-white flex items-end'>
            <Button
              variant='default'
              className='rounded-sm font-bold'
              onClick={handleSaveNote}
            >
              Save & Exit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Drawer open={isMistakeDrawerOpen} onOpenChange={setMistakeDrawerOpen}>
        <DrawerContent className='fixed inset-x-0 bottom-0 bg-primary border-primary'>
          <DrawerHeader className='p-4 m-0 text-white'>
            <DrawerTitle className='text-left py-1 text-xl'>
              Record Mistake
            </DrawerTitle>
          </DrawerHeader>
          <div className='p-4 bg-white space-y-6'>
            <div>
              <h3 className='text-lg font-semibold mb-4'>
                2. Classify mistake type & submit
              </h3>
              <div className='flex gap-4 mb-6'>
                <Button
                  variant={
                    mistakeFormData.type === 'Major' ? 'default' : 'outline'
                  }
                  onClick={() => handleMistakeTypeSelect('Major')}
                  className='flex-1'
                >
                  Major
                </Button>
                <Button
                  variant={
                    mistakeFormData.type === 'Minor' ? 'default' : 'outline'
                  }
                  onClick={() => handleMistakeTypeSelect('Minor')}
                  className='flex-1'
                >
                  Minor
                </Button>
                <Button
                  variant={
                    mistakeFormData.type === 'Tajweed' ? 'default' : 'outline'
                  }
                  onClick={() => handleMistakeTypeSelect('Tajweed')}
                  className='flex-1'
                >
                  Tajweed
                </Button>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div>
                <Label>Starting Verse</Label>
                <Input
                  type='number'
                  value={mistakeFormData.startVerse || ''}
                  onChange={(e) =>
                    setMistakeFormData((prev) => ({
                      ...prev,
                      startVerse: parseInt(e.target.value) || null,
                    }))
                  }
                />
              </div>
              <div>
                <Label>Ending Verse</Label>
                <Input
                  type='number'
                  value={mistakeFormData.endVerse || ''}
                  onChange={(e) =>
                    setMistakeFormData((prev) => ({
                      ...prev,
                      endVerse: parseInt(e.target.value) || null,
                    }))
                  }
                />
              </div>
            </div>

            <div className='border rounded-lg p-4'>
              <Button
                variant='outline'
                onClick={handleRecordingToggle}
                className='w-12 h-12 rounded-full p-0 flex items-center justify-center'
              >
                {isRecording ? (
                  <Pause className='h-6 w-6' />
                ) : (
                  <Play className='h-6 w-6' />
                )}
              </Button>
              <div className='mt-2 flex justify-between text-sm text-gray-500'>
                <span>0:08</span>
                <span>0:25</span>
              </div>
            </div>
          </div>
          <DrawerFooter className='p-4 bg-white flex justify-between'>
            <Button
              variant='outline'
              onClick={() => setMistakeDrawerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant='default'
              onClick={handleMistakeSubmit}
              disabled={!mistakeFormData.type}
            >
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
