import * as React from "react";

import { cn } from "../../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, ...props }, ref) => {
    return (
      <div className="relative w-full">
        {" "}
        {/* Wrap the input and icon in a relative container */}
        <input
          type={type}
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base transition-all ring-offset-background file:border-0 file:bg-transparent file:text-base file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            icon ? "pr-10" : "", // Add padding-right if an icon is provided
            className
          )}
          ref={ref}
          {...props}
        />
        {icon && ( // Conditionally render the icon if provided
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            {icon}
          </span>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
