import { useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import { Slider } from "./ui/slider";
import { Pause, Play, SkipBack, SkipForward, Volume } from "lucide-react";

interface AudioPlayerProps {
  audioSrc: string;
}

export function AudioPlayer({ audioSrc }: AudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(50);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleVolumeChange = (value: number[]) => {
    setVolume(value[0]);
  };

  const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (progressBarRef.current && audioRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const clickPositionRatio = clickX / rect.width;
      audioRef.current.currentTime = clickPositionRatio * duration;
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="flex flex-col items-center justify-center text-white">
      <div className="bg-transparent rounded-lg p-6 w-full md:w-4/5 xl:w-3/5 shadow-none border border-[#E2E2E2]">
        <div className="flex items-center justify-between mb-4">
          <div className="text-base font-medium text-[#5C5C5C]">
            10/16/24 - 10:00 AM
          </div>
        </div>
        <div
          className="w-full bg-[#D3D3D3] rounded-full h-2 mb-6 cursor-pointer"
          ref={progressBarRef}
          onClick={handleProgressBarClick}
        >
          <div
            className="bg-primary h-2 rounded-full"
            style={{
              width: duration > 0 ? `${(currentTime / duration) * 100}%` : "0%",
            }}
          />
          <div className="flex items-center justify-between text-gray-400 mt-1 text-sm">
            <span>{formatTime(currentTime)}</span>
            <span>{duration > 0 ? formatTime(duration) : "0:00"}</span>{" "}
          </div>
        </div>
        <div className="flex items-center justify-between mb-0">
          <div className="flex items-center mt-5 mb-2">
            <Button
              variant="icon"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime -= 10;
                }
              }}
              className="mr-[-15px]"
            >
              <SkipBack className="h-6 w-6 text-foreground" />
            </Button>
            <Button variant="icon" onClick={handlePlayPause}>
              {isPlaying ? (
                <Pause
                  className="h-10 w-10 text-white bg-primary rounded-full p-2 hover:bg-primary/80 transition-colors"
                  fill="white"
                />
              ) : (
                <Play
                  className="h-10 w-10 text-white bg-primary rounded-full p-2 hover:bg-primary/80 transition-colors"
                  fill="white"
                />
              )}
            </Button>
            <Button
              variant="icon"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.currentTime += 10;
                }
              }}
              className="ml-[-15px]"
            >
              <SkipForward className="h-6 w-6 text-foreground" />
            </Button>
          </div>
          <div className="flex items-center mt-5 mb-2">
            <Volume className="h-6 w-6 text-gray-400" />
            <Slider
              defaultValue={[volume]}
              max={100}
              step={1}
              onValueChange={handleVolumeChange}
              className="w-24"
            />
          </div>
        </div>
        <audio
          ref={audioRef}
          src={audioSrc}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={() => setIsPlaying(false)}
        />
      </div>
    </div>
  );
}
