import { Link, useLocation, matchPath } from "react-router-dom";
import { FolderSearch, LogOut, NotepadText, UserCheck } from "lucide-react";
import { pathHash } from '../lib/utils';
import { Button } from "./ui/button";
import { useAuth } from '../contexts/AuthContext';

const GeneralFooter = ({ pathHash }: { pathHash: string }) => {
  const location = useLocation();
  const { pathname } = location;
  const { logout, isAtLeastTeacher, user } = useAuth();


  // Extract student ID from the URL
  const match = matchPath({ path: "/student/:id/*" }, pathname);
  const studentId = isAtLeastTeacher ? match?.params?.id : user?.id;

  // Only show navigation items if we have a student ID (except for students page)
  // const showNav = studentId || pathHash === "students";

  return (
    <footer className="bg-white fixed bottom-0 w-full py-3 border-t border-[#C5C5C5]">
      <nav className="flex items-center justify-between max-w-7xl mx-auto px-8 py-2">
        <div className="flex flex-col items-center justify-center">
          {studentId && (
            <Link
              to={`/student/${studentId}`}
              replace={true}
              className={`text-center flex flex-col items-center ${pathHash === "student" ? "text-primary" : "text-[#444444CC]"
                }`}
            >
              <div className="leading-none">
                <NotepadText className="h-6 w-6" />
              </div>
              <div className="text-xs font-bold mt-1">Assignments</div>
            </Link>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          {studentId && (
            <Link
              to={`/student/${studentId}/attendance`}
              replace={true}
              className={`text-center flex flex-col items-center ${pathHash === "studentattendance" ? "text-primary" : "text-[#444444CC]"
                }`}
            >
              <div className="leading-none">
                <UserCheck className="h-6 w-6" />
              </div>
              <div className="text-xs font-bold mt-1">Attendance</div>
            </Link>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          {studentId && (
            <Link
              to="#resources"
              replace={true}
              className={`text-center flex flex-col items-center ${pathname === "#resources" ? "text-primary" : "text-[#444444CC]"
                }`}
            >
              <div className="leading-none">
                <FolderSearch className="h-6 w-6" />
              </div>
              <div className="text-xs font-bold mt-1">Resources</div>
            </Link>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          <Button
            variant="link"
            className={`text-center flex flex-col items-center ${pathname === "#resources" ? "text-primary" : "text-[#444444CC]"
              }`}
            onClick={logout}
          >
            <div className="leading-none">
              <LogOut className="text-grey" />
            </div>
            <div className="text-xs font-bold mt-1">Logout</div>
          </Button>
        </div>
      </nav>
    </footer>
  );
};

export function FooterNav() {
  const location = useLocation();
  const { pathname } = location;
  const path = pathHash(pathname);

  // Define paths where footer should be shown
  const footerPaths = [
    "students",
    "editassignment",
    "student",
    "dashboard",
    "studentattendance",
  ];

  if (!footerPaths.includes(path)) return null;

  return <GeneralFooter pathHash={path} />;
}