import React from 'react'
import api from '../services/axios'
import { User } from '../types/common'
import { useNavigate } from 'react-router-dom'

export const useGetUser = (id?: string | null) => {
    const [user, setUser] = React.useState<User>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(null)
    const navigate = useNavigate()
    const url = id != null ? `/api/user/${id}` : `/api/user/me`
    React.useEffect(() => {
        api.get<User>(url)
            .then(response => {
                setUser(response.data)
            })
            .catch(err => {
                console.error({ error: err })
                const statusCode = err.response?.status
                if (statusCode === 403) {
                    navigate(`/login`);
                }
                setError(err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id, navigate, url])

    return { user, loading, error }
}