import { Button } from "./ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { ArrowLeft } from "lucide-react";

export default function HeaderNav(props: {
  title: string | React.ReactElement;
  showDate?: boolean;
}) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const shouldShowBackArrow = !["/students", "/create-assignment","/dashboard"].includes(pathname)
  return (
    <div className="bg-primary w-full h-16 flex items-center px-5">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          {shouldShowBackArrow && (
            <Button
              variant="ghost"
              onClick={() => navigate(-1)}
              className="text-white hover:text-white/80 bg-transparent hover:bg-transparent"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
          )}
          <span className="text-white font-bold text-lg">
            {props.title ?? "Hifz Tracker"}
          </span>
        </div>

        {props.showDate && (
          <div className="text-white font-medium">
            {DateTime.now().toFormat("MM/dd/yyyy")}
          </div>
        )}
      </div>
    </div>
  );
}
