import React, { useState } from "react";
import { Card, CardHeader, CardContent } from "./ui/card";
import HeaderNav from "./HeaderNav";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Bookmark, Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "./ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { cn } from "../lib/utils";
import _ from 'lodash';
import { surahNameMap } from '../utils/surahUtils';
import { useMutateAssignment } from '../hooks/useMutateAssignment';

interface Section {
  surahNumber: number;
  startVerse: number;
  endVerse: number;
}

interface SectionProps {
  section: Section;
  onDelete: (surahNumber: number) => void;
}

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  surahName: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onConfirm, surahName }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Delete Section</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete Surah {surahName}? This action cannot be undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-3 sm:gap-0">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={onConfirm}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export function ReviewAssignment() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const { mutateAssignment } = useMutateAssignment();

  const [sections, setSections] = useState<Section[]>(
    Object.entries(state.verses as Record<string, { startVerse: number; endVerse: number }>)
      .map(([key, value]) => ({
        surahNumber: parseInt(key),
        startVerse: value.startVerse,
        endVerse: value.endVerse
      }))
  );

  const [deleteModalState, setDeleteModalState] = useState<{
    isOpen: boolean;
    surahNumber: number | null;
  }>({
    isOpen: false,
    surahNumber: null
  });

  const handleDeleteClick = (surahNumber: number) => {
    setDeleteModalState({
      isOpen: true,
      surahNumber
    });
  };

  const handleConfirmDelete = () => {
    if (deleteModalState.surahNumber) {
      setSections(sections.filter(
        section => section.surahNumber !== deleteModalState.surahNumber
      ));
      setDeleteModalState({ isOpen: false, surahNumber: null });
    }
  };

  const SectionContent: React.FC<SectionProps> = ({ section }) => (
    <CardContent className="py-4 px-6 border-t border-gray-300">
      <div className="flex justify-between font-bold mb-1">
        <div>Surah</div>
        <div>Verses</div>
      </div>
      <div className="flex justify-between mb-4">
        <div>{surahNameMap[section.surahNumber]} ({section.surahNumber})</div>
        <div>{section.startVerse}-{section.endVerse}</div>
      </div>
      <div className="flex justify-end font-bold mt-5">
        <div>
          <Button
            variant="link"
            className="text-end font-bold p-0 me-3 underline"
            disabled
          >
            Edit
          </Button>
          <Button
            variant="link"
            className="text-end font-bold p-0 underline"
            onClick={() => handleDeleteClick(section.surahNumber)}
          >
            Delete
          </Button>
        </div>
      </div>
    </CardContent>
  );

  const handleSubmitLesson = async () => {
    const studentId = state.user.id;
    const assignment = {
      ...state.assignment,
      ...(date != null ? { dueDate: date } : {}),
      status: "draft"
    };

    // Send the sections array directly
    const requestBody = {
      assignment,
      sections
    };

    await mutateAssignment(studentId, requestBody, state.mode);
  };

  if (state?.user == null) {
    return (<div>You do not have permission to view this page.</div>);
  }

  return (
    <>
      <HeaderNav title={`${state.user.name}`} />
      <div className="p-4 pb-24 w-full mx-auto md:w-4/5 xl:w-3/5">
        <h2 className="text-2xl font-bold mx-2 mb-3 mt-4">
          Review Lesson Details
        </h2>
        <div className="mx-2 mb-4 font-medium">Review & submit your lesson</div>

        <Card>
          <CardHeader className="bg-[#F4F4F4] border-b py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center text-primary font-semibold">
                <Bookmark className="mr-2 h-5 w-5" fill="#006766" /> {_.startCase(_.toLower(state.type))} Lesson
              </div>
            </div>
          </CardHeader>

          {sections.map((s, k) => (
            <SectionContent
              key={k}
              section={s}
              onDelete={() => handleDeleteClick(s.surahNumber)}
            />
          ))}
        </Card>

        <div className="mx-2 mt-5 mb-2 font-medium">
          Enter Due Date (Optional)
        </div>
        <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[280px] justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
              onClick={() => setOpenCalendar(!openCalendar)}
            >
              <CalendarIcon className="mr-2 h-4 w-4 text-primary" />
              {date ? format(date, "PPP") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={date}
              onSelect={(value) => {
                if (value) {
                  setDate(value);
                  setOpenCalendar(false);
                }
              }}
            />
          </PopoverContent>
        </Popover>
      </div>

      <DeleteModal
        isOpen={deleteModalState.isOpen}
        onClose={() => setDeleteModalState({ isOpen: false, surahNumber: null })}
        onConfirm={handleConfirmDelete}
        surahName={deleteModalState.surahNumber ? surahNameMap[deleteModalState.surahNumber] : ''}
      />

      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(`/student/${state.user.id}`, { state, replace: true })}
          className="mr-2 rounded-sm font-bold text-primary"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          onClick={handleSubmitLesson}
          className="rounded-sm font-bold"
          disabled={sections.length === 0}
        >
          {_.startCase(state.mode ?? 'Submit')} Lesson
        </Button>
      </div>
    </>
  );
}