import React from 'react'
import api from '../services/axios'
import { AttendanceAggregate } from '../types/common'

export const useGetAggregateAttendance = (id?: string | null) => {
    const [monthlyAttendanceSummary, setmMnthlyAttendanceSummary] = React.useState<AttendanceAggregate>()
    const [yearlyAttendanceSummary, setYearlyAttendanceSummary] = React.useState<AttendanceAggregate>()
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(null)

    React.useEffect(() => {
        api.get<{ month: AttendanceAggregate, year: AttendanceAggregate }>(`/api/user/${id}/attendances`)
            .then(response => {
                setmMnthlyAttendanceSummary(response.data.month)
                setYearlyAttendanceSummary(response.data.year)
            })
            .catch(err => {
                console.error(err)
                setError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id])

    return { monthlyAttendanceSummary, yearlyAttendanceSummary, loading, error }
}