import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { Card, CardHeader, CardContent } from "./ui/card";
import HeaderNav from "./HeaderNav";
import { useLocation, useNavigate } from "react-router-dom";
import { CircleCheck, Search } from "lucide-react";
import _ from 'lodash';
import { Surah, surahs } from '../utils/surahUtils';
import { AssignmentMutationMode } from '../types/common';

export function CreateAssignment() {
  const [filteredSurahs, setFilteredSurahs] = React.useState<Surah[]>([]);
  const { state } = useLocation();
  const [entireVerse, setEntireVerse] = React.useState(
    new Array(surahs.length).fill(false)
  );
  const [verses, setVerses] = React.useState<{
    [key: number]: { startVerse: number | null; endVerse: number | null };
  }>({});
  const navigate = useNavigate();

  React.useEffect(() => {
    if (Object.keys(surahs).length > 0) {
      setFilteredSurahs(surahs);
    }
  }, []);

  const filterItems = (searchTerm: any) => {
    const term = searchTerm.target.value.toLowerCase();
    const filteredItems = surahs.filter((surah: Surah) =>
      surah.name.toLowerCase().includes(term)
    );
    setFilteredSurahs(filteredItems);
  };

  const handleConfirmLesson = () => {
    const selectedData = {
      verses,
      mode: AssignmentMutationMode.create
    };
    navigate(`/student/${state.user?.id}/review-assignment`, {
      state: {
        assignment: {
          studentId: state.user.id,
          type: state.type
        },
        ...selectedData,
        user: state.user,
        mode: AssignmentMutationMode.create
      },
      replace: true
    });
  };

  const handleVerseChange = (
    surahNumber: number,
    type: "startVerse" | "endVerse",
    value: number
  ) => {
    setVerses((prev) => ({
      ...prev,
      [surahNumber]: {
        ...prev[surahNumber],
        [type]: value,
      },
    }));
  };

  const handleClearVerse = (surahNumber: number) => {
    // Clear the verses state for this surah
    setVerses((prev) => {
      const newVerses = { ...prev };
      delete newVerses[surahNumber];
      return newVerses;
    });

    // Clear the entireVerse state for this surah
    setEntireVerse((prev) => {
      const newEntireVerse = [...prev];
      newEntireVerse[surahNumber] = false;
      return newEntireVerse;
    });
  };

  return (
    <>
      <HeaderNav title={`Add New Lesson for ${state.user.name}`} />
      <Card className="w-full pb-24 mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
        <CardContent className="p-0">
          <CardHeader className="px-4">
            <p>Select a surah and the required verses</p>
            <div className="my-4">
              <Input
                type="text"
                placeholder="Search Surahs"
                onChange={filterItems}
                icon={<Search className="w-4 h-4 opacity-50" />}
              />
            </div>
          </CardHeader>

          <Accordion type="multiple" className="mb-10">
            {filteredSurahs.map((d: Surah, i: number) => (
              <AccordionItem key={i} value={`${d.surahNumber}`}>
                <AccordionTrigger className="px-5">
                  <div className="flex justify-between items-center w-full">
                    <span className="font-bold flex items-center">
                      {(entireVerse[d.surahNumber] ||
                        (verses[d.surahNumber]?.startVerse && verses[d.surahNumber]?.endVerse)) && (
                          <CircleCheck
                            className="w-5 h-5 text-white"
                            fill="#006766"
                          />
                        )}

                      <span
                        className={
                          entireVerse[d.surahNumber] ||
                            (verses[d.surahNumber]?.startVerse && verses[d.surahNumber]?.endVerse)
                            ? "text-primary"
                            : ""
                        }
                      >
                        {d.name}
                      </span>
                    </span>
                    <span className="text-gray-500 font-normal">
                      {d.endVerse - d.startVerse + 1} Verses
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="px-5">
                  <div className="flex align-items-center gap-7 mt-4">
                    <div className="w-full">
                      <Label>Starting Verse</Label>
                      <Input
                        type="number"
                        placeholder={`${d.startVerse}`}
                        min={d.startVerse}
                        max={d.endVerse}
                        value={verses[d.surahNumber]?.startVerse || ""}
                        onChange={(e) =>
                          handleVerseChange(
                            d.surahNumber,
                            "startVerse",
                            +e.target.value
                          )
                        }
                        disabled={entireVerse[d.surahNumber]}
                        className="mt-2"
                      />
                    </div>
                    <div className="w-full">
                      <Label>Ending Verse</Label>
                      <Input
                        type="number"
                        placeholder={`${d.endVerse}`}
                        min={d.startVerse}
                        max={d.endVerse}
                        value={verses[d.surahNumber]?.endVerse || ""}
                        onChange={(e) =>
                          handleVerseChange(
                            d.surahNumber,
                            "endVerse",
                            +e.target.value
                          )
                        }
                        disabled={entireVerse[d.surahNumber]}
                        className="mt-2"
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center">
                      <Switch
                        id={`entire-verse-${d.surahNumber}`}
                        checked={entireVerse[d.surahNumber] || false}
                        onCheckedChange={(checked) => {
                          const updateVerse = [...entireVerse];
                          updateVerse[d.surahNumber] = checked;
                          if (checked) {
                            handleVerseChange(d.surahNumber, "startVerse", d.startVerse);
                            handleVerseChange(d.surahNumber, "endVerse", d.endVerse);
                          }

                          setEntireVerse(updateVerse);
                        }}
                      />
                      <Label
                        htmlFor={`entire-verse-${d.surahNumber}`}
                        className={`ml-2 ${entireVerse[d.surahNumber] ? "text-primary" : ""
                          }`}
                      >
                        Entire Surah
                      </Label>
                    </div>
                    <Button
                      variant="link"
                      className="text-sm p-0 underline font-bold"
                      onClick={() => handleClearVerse(d.surahNumber)}
                    >
                      Clear
                    </Button>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
      </Card>
      <div className="bg-white fixed bottom-0 w-full py-3 px-4 border-t border-[#C5C5C5] flex justify-end">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mr-2 rounded-sm font-bold text-primary"
        >
          Cancel
        </Button>
        <Button
          variant="default"
          onClick={handleConfirmLesson}
          className="rounded-sm font-bold"
          disabled={_.isEmpty(verses)}
        >
          Review Lesson
        </Button>
      </div>
    </>
  );
}