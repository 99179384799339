import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetUsers } from "../hooks/useGetUsers";
import HeaderNav from "./HeaderNav";
import { CircleAlert, CircleCheck, CircleX, Search } from "lucide-react";
import { Table, TableRow, TableBody, TableCell } from "./ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Input } from "./ui/input";
import { Skeleton } from "./ui/skeleton";
import api from '../services/axios';
import { toast } from '../hooks/useToast';
import { AxiosError } from 'axios';
import { BackendError } from '../types/error';
import { User } from '../types/common';

export function Students() {
  const { users, loading, error } = useGetUsers();
  const [apiError, setApiError] = useState<string | null>(null)
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);
  const [attendance, setAttendance] = React.useState<{ [key: string]: string }>(
    {}
  );

  React.useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(users);
    }
  }, [users]);

  const filterItems = (searchTerm: React.ChangeEvent<HTMLInputElement>) => {
    const term = searchTerm.target.value.toLowerCase();
    const filteredItems = users.filter((user: User) => {
      const name = `${user.name}`.toLowerCase();
      return name.includes(term);
    });
    setFilteredUsers(filteredItems);
  };

  const handleAttendanceChange = (userId: number, value: string) => {
    setAttendance((prev) => ({
      ...prev,
      [userId]: value,
    }));
    api
      .post('/api/attendance', {
        studentId: userId, status: value
      })
      .then(response => {
        // console.log({ response })
      })
      .catch((error: AxiosError<BackendError>) => {
        const title = error?.response?.data?.title ?? 'An Error has occurred'
        const description = error?.response?.data?.message ?? 'Lesson could not be created'
        setApiError(description)
        toast({
          title,
          description,
          icon: (
            <CircleAlert
              className="text-white dark:text-slate-900"
              fill="red"
            />
          ),
        })
      });
  };

  return (
    <>
      <HeaderNav title="All Students" showDate />
      {error && (
        <>
          <div className="text-red-500 p-3">We encountered a problem</div>
          <div className="text-red-500 p-3">{(error as any).message}</div>
          <div className=" p-3">Please try <a href='/'>logging in</a> again.</div>
        </>
      )}
      {apiError && (
        <>
          <div className="text-red-500 p-3">{(apiError as any).message}</div>
        </>
      )}

      <Card className="w-full mx-auto border-none shadow-none md:w-4/5 xl:w-3/5">
        <CardContent className="p-0">
          {error == null && <CardHeader className="px-5">
            <Input
              type="text"
              placeholder="Search Students"
              className="py-5 rounded-sm"
              icon={<Search className="w-4 h-4 opacity-50" />}
              onChange={filterItems}
            />
          </CardHeader>}
          <Table className="p-0 m-0">
            <TableBody>
              {loading
                ? Array.from({ length: 15 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton className="h-6 w-full max-w-72" />
                    </TableCell>
                    <TableCell className="flex justify-end items-center">
                      <Skeleton className="h-6 w-full max-w-48" />
                    </TableCell>
                  </TableRow>
                ))
                : filteredUsers.map((user) => {
                  const userId = `${user.id}`;
                  return (
                    <TableRow key={user.id}>
                      <TableCell className="pl-7 py-3 text-base">
                        <Link
                          to={`/student/${user.id}`}
                          state={{ user }}
                          className="hover:underline"
                        >
                          <div className="font-medium">
                            {user.name}
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell className="flex py-3 justify-end items-center min-w-[160px] pr-5">
                        <Select
                          value={attendance[userId] || user.attendanceStatus || ""}
                          onValueChange={(value) =>
                            handleAttendanceChange(+user.id, value)
                          }
                        >
                          <SelectTrigger
                            className={`md:w-[150px] max-w-[150px] font-medium border${[attendance[userId], user.attendanceStatus].includes("present")
                              ? " bg-[#E4F3E5] text-[#309C34] border-0 dark:bg-green-500 dark:text-green-100"
                              : [attendance[userId], user.attendanceStatus].includes("late")
                                ? " bg-[#FBF2D9] text-[#B18708] border-0 dark:bg-yellow-500 dark:text-yellow-100"
                                : [attendance[userId], user.attendanceStatus].includes("absent")
                                  ? " bg-[#F3E4E4] text-[#9C3030] border-0 dark:bg-red-500 dark:text-red-100"
                                  : ""
                              }`}
                          >
                            <SelectValue placeholder="Attendance" />
                          </SelectTrigger>
                          <SelectContent className="p-2 min-w-[170px]">
                            <SelectGroup>
                              <SelectItem
                                value="present"
                                className="bg-[#E4F3E5] text-[#309C34] font-medium mb-2 py-2 border-0 dark:bg-green-500 dark:text-green-100"
                              >
                                <div className="flex items-center">
                                  <CircleCheck className="mr-2 h-4 w-4" />
                                  <span>Present</span>
                                </div>
                              </SelectItem>
                              <SelectItem
                                value="late"
                                className="bg-[#FBF2D9] text-[#B18708] font-medium mb-2 py-2 border-0 dark:bg-yellow-500 dark:text-yellow-100"
                              >
                                <div className="flex items-center">
                                  <CircleAlert className="mr-2 h-4 w-4" />
                                  <span>Late</span>
                                </div>
                              </SelectItem>
                              <SelectItem
                                value="absent"
                                className="bg-[#F3E4E4] text-[#9C3030] font-medium py-2 border-0 dark:bg-red-500 dark:text-red-100"
                              >
                                <div className="flex items-center">
                                  <CircleX className="mr-2 h-4 w-4" />
                                  <span>Absent</span>
                                </div>
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
