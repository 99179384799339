import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect } from 'react';
import { AxiosError } from 'axios';
import api from '../services/axios';
import {
  LoginCredentials,
  LoginResponse,
  LoginResult
} from '../types/auth';
import { BackendError } from '../types/error';
import { useNavigate } from 'react-router-dom';
import { User } from '../types/common';
import { Skeleton } from '../components/ui/skeleton';

export interface AuthContextType {
  user: User | null;
  login: (username: string, password: string) => Promise<LoginResult>;
  logout: () => void;
  isAuthenticated: boolean;
  isAtLeastTeacher: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Helper function to check if user is at least a teacher
  const checkIsAtLeastTeacher = (userRole?: string): boolean => {
    return !!userRole && ['teacher', 'admin'].includes(userRole.toLowerCase());
  };

  // Initialize auth state from token
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          const response = await api.get<User>('/api/user/me');
          setUser(response.data);
        } catch (error) {
          localStorage.removeItem('token');
          delete api.defaults.headers.common['Authorization'];
        }
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  const login = useCallback(async (username: string, password: string): Promise<LoginResult> => {
    try {
      const response = await api.post<LoginResponse>('/api/auth/login', {
        username,
        password
      } as LoginCredentials);

      const { token, user: userData } = response.data;

      localStorage.setItem('token', token);
      setUser(userData);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return {
        success: true,
        isAtLeastTeacher: checkIsAtLeastTeacher(userData?.role)
      };
    } catch (error) {
      const axiosError = error as AxiosError<BackendError>;
      return {
        success: false,
        error: axiosError.response?.data || 'Login failed',
        isAtLeastTeacher: false
      };
    }
  }, []);

  const logout = useCallback((): void => {
    localStorage.removeItem('token');
    setUser(null);
    delete api.defaults.headers.common['Authorization'];
    navigate('/');
  }, [navigate]);

  if (isLoading) {
    return <Skeleton />;
  }

  const value: AuthContextType = {
    user,
    login,
    logout,
    isAuthenticated: !!user,
    isAtLeastTeacher: checkIsAtLeastTeacher(user?.role)
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};